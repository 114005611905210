import WithRouter from "../../components/common/withRouter";
import styles from "./gallery.module.css";
import NavHeader from "../../components/navHeader/navHeader";
import { artDatas } from "./gallery-archive";
import { useEffect, useState } from "react";
import Modal from "../../components/modal/modal";
import ImageCard from "./image-card";
import TopButton from "../../components/topButton/topButton";
import MainLogo from "../../components/mainLogo/mainLogo";
import ZoomModal from "../../components/zoomModal/zoomModal";

const Gallery = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [zoomModalOpen, setZoomModalOpen] = useState(false);
  const [currentIdx, setCurrentIdx] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const [ardDiv, setArtDiv] = useState(null);

  const openModal = (idx) => {
    setScrollY(window.scrollY);
    setModalOpen(true);
    setCurrentIdx(idx);
  };

  useEffect(() => {
    const gallery = document.getElementById("gallery");
    gallery.scrollTo({ top: scrollY });
    gallery.style.touchAction = modalOpen ? "pinch-zoom" : "pan-y";
  }, [modalOpen]);

  useEffect(() => {
    setArtDiv(
      artDatas.map((v, idx) => {
        return (
          <div
            className={styles.galleryImgWrapper}
            // 이미지 삽입할 땐 백그라운드로 넣는 게 답일 때도 있다.
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/preview/${v.file})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
            onClick={openModal.bind(this, idx)}
            key={idx}
          ></div>
        );
      })
    );
    if (props.router.params.title) {
      const findIndex = artDatas.findIndex(({ file }) => file.split(".")[0] === props.router.params.title);
      if (findIndex !== -1) {
        openModal(findIndex);
      } else {
        alert("요청한 작품을 찾을 수 없습니다.");
      }
    }
  }, []);

  return (
    <div className={styles.gallery} id="gallery">
      <MainLogo />
      <NavHeader selected={"gallery"} />
      <div className={styles.galleryGrid}>{ardDiv}</div>
      {modalOpen &&
        (!zoomModalOpen ? (
          <Modal
            setModalOpen={setModalOpen}
            setZoomEnable={setZoomModalOpen}
            children={<ImageCard currentIdx={currentIdx} setCurrentIdx={setCurrentIdx} />}
          />
        ) : (
          <ZoomModal
            setZoomEnable={setZoomModalOpen}
            children={
              <div
                className={styles.galleryZoomImg}
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/images/detail/${artDatas[currentIdx].file})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
            }
          />
        ))}
      <TopButton />
    </div>
  );
};

export default WithRouter(Gallery);
