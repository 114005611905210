function StaticBackground({ imageFile }) {
  return (
    <div
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/background/${imageFile})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        zIndex: -1,
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "30px",
        overflow: "auto",
      }}
    ></div>
  );
}

export default StaticBackground;
