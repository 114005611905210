const introduce = [
  "희희는 포스트 아포칼립스라는 SF장르로",
  "인류문명이 환경오염에 의해 멸망한 이후",
  "비대해진 비둘기와 개미가 범람한 세계를 이야기한다.",
  "",
  "특유의 두들 스타일과 원색의 화려한 색감은",
  "보는이들에게 즐거움을 선사하지만,",
  "역설적이게도 전하고자 하는 메세지는 결코 유쾌하지 않다.",
  "",
  "예를들면,",
  "지구가 계속해서 우리에게 구조신호를 보내고 있음을",
  "모스부호를 패턴으로 녹여내는 것.",
];

const education = "BFA Graphic design SVA, New York, United States";

const history = [
  { date: "2022.04", title: `‘줄탁동시’ 성균관대학교 성균갤러리 (단체전)` },
  { date: "2022.05", title: `‘어반펑크’ 청파동 문화의 거리 (2인전)` },
  { date: "2022.06", title: `‘동시다발 展’ 문래동, 을지로, 연남동 (단체전)` },
  { date: "2022.11", title: `‘미러엑스 展’ 삼청동 임스튜디오 (단체전)` },
  {
    date: "2023.02",
    title: `‘medium is massage 展’ 꼴라보하우스 문래 (단체전)`,
  },
  { date: "2023.03", title: `‘BAMA BUSAN’ 부산 벡스코 아트페어` },
  { date: "2023.04", title: `아트스텔라 창원 3인전` },
  {
    date: "2023.05",
    title: `"어린왕자의 꿈을 찾아서’ 판교 현대백화점 (단체전) `,
  },
  { date: "2023.06", title: `‘The GIAF’ 서울 신라호텔 아트페어 (단체전)` },
  { date: "2023.07", title: `‘넷마블 월드’ 일산 원마운트` },
  { date: "2023.08", title: `‘동물작가 展’ 강남 아트버디 (5인전)` },
  { date: "2023.11", title: `‘연말 선물展’ 영풍문고 본점 종각 (단체전)` },
  { date: "2023.12", title: `‘키치온더 탭’ 강남 부띠끄모나코미술관 (단체전)` },
  { date: "2024.03", title: `‘Bank Art Fair’ 소공동 롯데호텔 본점 (단체전)` },
  { date: "2024.08", title: `‘BAF’ 세텍 아트페어 (단체전)` },
  { date: "2024.09", title: `‘비둘기 출몰지역’ 초대 개인전 갤러리 컬린` },
];

export { introduce, education, history };
